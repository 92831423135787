.svg-icon {
  transition: fill 0.3s ease-in-out;
  @include mq($until: xs) {
    max-width: rem(25px);
  }
}
.contact-data {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: rem(50px);
  grid-gap: rem(30px);

  @include mq($until: md) {
    grid-template-columns: 1fr;
  }

  &--homepage {
    grid-template-columns: 1fr;
    @include mq($until: lg) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include mq($until: md) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include mq($until: sm) {
      grid-template-columns: 1fr;
    }
  }

  &__section {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    p {
      margin-bottom: 0;
    }
    &--name {
      display: block;
      p {
        font-size: rem(20px);
        font-weight: $fw-semi-bold;
      }
    }
    &--social .svg-icon {
      max-width: unset;
      margin-right: rem(5px);
      @include mq($until: xs) {
        max-width: rem(35px);
      }
    }
    &--social a:hover .svg-icon {
      fill: $color-main;
    }
    &--footer {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        height: rem(20px);
        fill: black;
      }
    }
  }

  &__icon {
    margin-right: rem(15px);
  }
  &__content {
    &--phones,
    &--emails {
      display: flex;
      flex-direction: column;
    }
  }
}

.footer__nav {
  li {
    margin-right: 0px;
    margin-left: rem(25px);
  }
  @include mq($until: lg) {
    justify-content: center;
    margin: rem(30px) 0;
  }
  .header__menuLink {
    color: black;
  }
}

.footer-contact-data {
  display: flex;
  justify-content: end;
  &__section {
    &--place,
    &--hours {
      padding-left: rem(30px);
      padding-top: rem(50px);
      text-align: start;
    }
  }
}
.footer-social {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  svg {
    fill: #767676;
    &:hover {
      fill: black;
    }
  }
  @include mq($until: lg) {
    justify-content: center;
  }
  .footer-contact-data__section--social {
    align-items: center;
    display: flex;
  }
  a {
    padding-left: rem(10px);
  }
  @include mq($until: lg) {
    .footer-social {
      justify-content: center;
    }
  }
}

.footer-contact-container {
  align-items: center;
  @include mq($from: md) {
    min-height: rem(200px);
    width: 100%;
  }
  @include mq($until: md) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: -8%;
    & img {
      max-width: 50%;
    }
  }
  .footer-contact-data {
    justify-content: flex-end;
    @include mq($until: lg) {
      justify-content: center;
    }
    &__section {
      @include mq($until: lg) {
        &--place,
        &--hours {
          @include mq($until: lg) {
            padding: rem(30px);
            text-align: center;
          }
        }
      }
    }
  }
}
