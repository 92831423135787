.icons-set {
  display: grid;
  text-align: center;
  gap: 1vw;

  &__item {
    display: flex;
    flex-direction: row;
    padding: 0;
    align-items: center;
    max-width: 80%;
    justify-self: center;
    @include mq($until: lg) {
      flex-direction: column;
    }

    img {
      width: rem(130px);
      // filter: opacity(0.8) drop-shadow(0 0 0 $color-main) contrast(250%) contrast(190%) brightness(110%);
    }
    span {
      margin: 0 rem(16px);
    }
    &::before {
      content: none;
    }
  }

  &__item-text {
    font-weight: 600;
    font-size: rem(16px);
  }

  @include mq($until: md) {
    &__item {
      img {
        width: rem(60px);
      }
    }
    &__item-text {
      font-size: rem(15px);
    }
  }

  @include mq($until: sm) {
    &__item-text {
      font-size: rem(13px);
    }
  }
}

.icons-grid {
  &--8 {
    grid-template-columns: repeat(8, 1fr);
  }

  &--7 {
    grid-template-columns: repeat(7, 1fr);
  }

  &--6 {
    grid-template-columns: repeat(6, 1fr);
  }

  &--5 {
    grid-template-columns: repeat(5, 1fr);
  }

  &--4 {
    grid-template-columns: repeat(4, 1fr);
  }

  &--3 {
    grid-template-columns: repeat(3, 1fr);
  }

  &--2 {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq($until: md) {
    &--8 {
      grid-template-columns: repeat(4, 1fr);
    }

    &--7 {
      grid-template-columns: repeat(3, 1fr);
    }

    &--6 {
      grid-template-columns: repeat(3, 1fr);
    }

    &--5 {
      grid-template-columns: repeat(2, 1fr);
    }

    &--4 {
      grid-template-columns: repeat(2, 1fr);
    }

    &--3 {
      grid-template-columns: repeat(2, 1fr);
    }

    &--2 {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.subsite {
  .icons-service-set {
    margin: 0px;
    ul {
      li {
        padding: 0px;
      }
    }
    margin-left: rem(10px);
    &__item-box {
      background-color: #fff;
      margin: rem(10px);
      padding: rem(20px);
      border-radius: rem(5px);
      transition: 200ms;
      min-height: 80px;
      min-width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: rem(40px);
      }
    }
    &__item-text {
      color: rgba(255, 255, 255, 0);
      // font-size: 0px;
      font-size: rem(0px) !important;
      font-weight: $fw-bold;
      // transform: scale(1);
      visibility: visible;
      transition: 150ms;
      @include mq($until: lg) {
        font-size: rem(14px);
      }
    }
  }
}

.icons-service-set {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  margin-left: rem(30px);
  flex-flow: wrap;
  img {
    pointer-events: none;
  }
  @include mq($until: lg) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    &__item-box {
      padding: rem(30px);
      border-radius: rem(15px);
      max-width: rem(120px);
      max-height: rem(120px);
      img {
        height: rem(40px);
      }
    }
  }

  li::before {
    content: none;
  }
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__item-box {
    background-color: #fff;
    margin: rem(15px);
    padding: rem(40px);
    border-radius: rem(15px);
    transition: 200ms;
    min-height: 80px;
    min-width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: rem(40px);
    }
  }
  &__item-text {
    color: $color-font-dark;
    font-size: rem(14px);
    font-weight: $fw-bold;
    transform: scale(1);
    visibility: visible;
    transition: 150ms;
    @include mq($until: lg) {
      font-size: rem(14px);
    }
    &.hide {
      visibility: hidden;
      transition: 150ms;
    }
  }
}
