.carousel {
    &-control-prev,
    &-control-next {
        align-items: flex-end;
        opacity: 1 !important;
        &-icon {
            height: 3rem;
            width: 2rem;
        }
    }
    &-control-prev {
        right: 8vw;
        bottom: 2vw;
        left: unset !important;
        &-container {
            padding: rem(10px);
            display: flex;
            justify-content: center;
            align-items: center;
            height: rem(80px);
            width: rem(80px);
            background-color: #ffffff54;
            backdrop-filter: blur(15px);
            border-radius: 100%;
        }
        &-icon {
            margin-left: rem(-8px);
        }
    }
    &-control-next {
        right: 1vw;
        bottom: 2vw;
        &-container {
            @extend .carousel-control-prev-container;
        }
        &-icon {
            margin-left: rem(8px);
        }
    }
    a {
        position: absolute;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        color: #fff;
        font-size: 2rem;
        text-align: center;
        top: 80%;
        bottom: 0;
        right: 45%;
        &:hover {
            color: $color-main;
        }
    }
}

@include mq($until: sm) {
    .carousel {
        &-control-prev,
        &-control-next {
            bottom: 10px !important;
        }
        &-control-prev {
            right: 90px !important;
            &-container {
                height: 60px !important;
            }
        }
        &-control-next {
            right: 10px !important;
        }
        .carousel a {
            font-size: 1.5rem !important;
            top: 355px !important;
            bottom: unset !important;
            right: 0 !important;
            left: -150px !important;
        }
    }
}
