.top-background-img {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    // height: 200vh;
    width: 100%;
    position: absolute;
    z-index: -1;
    filter: opacity(0.1) drop-shadow(0 0 0 $color-background);
    @include mq($until: xs) {
        // height: 160vh;
    }
    @include mq($from: xs, $until: md) {
        // height: 120vh;
    }
    @include mq($until: xl) {
        background-size: cover;
    }
    @include mq($until: md, $and: "(orientation: landscape)") {
        // height: 300vh;
    }
    @include mq($until: lg) {
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}
.top-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    @include mq($until: xs) {
        height: 140vh;
    }
    @include mq($until: xs, $and: "(min-height: 670px)") {
        height: 130vh;
    }
    @include mq($from: xs, $until: lg) {
        height: 120vh;
    }
    @include mq($until: md, $and: "(orientation: landscape)") {
        height: 300vh;
    }
    &__box-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
    }
    &__header-box {
        display: flex;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        padding: 0 rem(150px);
        @include mq($until: lg) {
            padding: rem(30px);
        }
        @include mq($until: sm) {
            padding: rem(20px);
        }
        &--left {
            left: 0;
        }
        &--right {
            right: 0;
        }
        &--border {
            border: 1px solid $color-main;
            margin: 1.25em 1.25em 7.5em 1.25em;
            box-shadow: 0 0 0 1.25em rgba(255, 255, 255, 0.8);
        }
    }
}
.about-box2 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    h1,
    h2 {
        color: $color-font-dark;
    }
    h1 {
        font-size: rem(42px);
        padding-bottom: rem(10px);
    }
    h2 {
        font-size: rem(32px);
        margin-top: 0px;
    }
    .icons-set__item-text {
        font-size: rem(14px) !important;
        color:$color-main;
        transition: 200ms;
    }
    img {
        @include mq($until: xs) {
            max-width: 250%;
        }
    }
    .icon-box {
        position: relative;
        width: 50vw;
        z-index: 10;
        background-color: white;
        margin-top: rem(100px);
        img {
            // height: rem(60px);
            width: rem(50px);
            padding-bottom: rem(10px);
        }
    }
    .line {
        border-bottom: solid 2px #b93b34;
        width: 100%;
        height: 50%;
    }
}

.about-box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    h1,
    h2 {
        color: $color-font-dark;
        @include mq($until: md) {
            color: $color-font-light;
        }
    }
    h1 {
        font-size: rem(42px);
        border-bottom: 3px solid $color-main;
        padding-bottom: rem(10px);
    }
    h2 {
        font-size: rem(32px);
        margin-top: 0px;
    }
    &__right {
        @include mq($until: xs) {
            display: flex !important;
            & img {
                position: absolute;
                top: rem(-65px);
                left: -4.3%;
                z-index: -1;
                filter: brightness(0.7);
            }
        }
        @include mq($from: xs, $until: md) {
            display: flex !important;
            & img {
                position: absolute;
                bottom: 5vh;
                left: 0%;
                z-index: -1;
                filter: brightness(0.7);
            }
        }
    }
    .icons-set__item-text {
        font-size: rem(14px) !important;
        &:hover {
            color:$color-main;
            transition: 200ms;

        }
    }
    img {
        @include mq($until: xs) {
            max-width: 250%;
        }
    }
    .icon-box {
        position: relative;
        width: 50vw;
        z-index: 10;
        background-color: white;
        margin-top: rem(100px);
        img {
            // height: rem(60px);
            width: rem(50px);
            padding-bottom: rem(10px);
        }
        @include mq($until: md) {
            width: 80vw;
        }
    }
    .line {
        border: solid 2px #b93b34;
        width: rem(404px);
        position: absolute;
        top: 68%;
    }
    .line-link {
        position: absolute;
        top: 65%;
        left: 53%;
    }

    // &__right {
    //     position: absolute;
    //     right: 0px;
    //     top: -150px;
    //     align-items: flex-end;
    //     img {
    //         max-width: 80%;
    //     }
    // }
}

.red-background,
.red-background-services {
    position: absolute;
    mix-blend-mode: multiply;
}
.red-img-services {
    @include mq($until: sm) {
        max-width: 70%;
        order: 1;
    }
}
.carousel-image {
    width: 100%;
    max-height: 70%;
}
