.news-card {
    overflow: hidden;
    // box-shadow: 0 10px 38px -9px rgba(0, 0, 0, 0.25);
    -webkit-transition: box-shadow 0.3s, -webkit-transform 0.3s;
    transition: box-shadow 0.3s, -webkit-transform 0.3s;
    transition: box-shadow 0.3s, transform 0.3s;
    transition: box-shadow 0.3s, transform 0.3s, -webkit-transform 0.3s;

    &__image {
        width: 100%;
        padding-top: 60%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }
    &__text-container {
        padding: 30px 5px;
        @include mq($until: lg) {
            padding: 15px 20px;
        }
    }
    &__header {
        font-size: 1.2rem;
        margin-top: 15px;
        transition: color 0.2s;
        &:hover {
            color: $color-font-dark;
        }
    }
    &__more {
        color: $color-main;
        font-weight: 600;
        text-decoration: underline;
        &:hover {
            color: $color-font-dark;
        }
    }
}
