.subsite {
  // margin-bottom: 50px;
  padding-top: rem(80px);
  &__header {
    // border-bottom: 1px solid $color-main;
    width: 100%;
    height: 30vw;
    display: flex;
    align-items: flex-end;
    // margin-bottom: 40px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom -5vw center;

    @include mq($until: lg) {
      height: 28vw;
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto;
    }

    @include mq($until: xs) {
      height: 40vw;
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto;
    }

    div {
      background-color: $color-background;
      width: 100%;
      padding-top: rem(15px);
      padding-bottom: rem(15px);
      display: flex;
      align-content: center;
      h1 {
        margin: 0;
      }
    }
  }
}

.subsite-header-title {
  border-bottom: rem(3px) solid $color-main;
  margin: 4vw 8vw 2vw 8vw;
  h1 {
    color: $color-font-dark;
  }
}

.subsite-footer-contact.contact-section {
  background-color: #fff;
  left: 8%;
  width: 92%;
  display: flex;
  flex-direction: column;
  min-height: 30vh;
  justify-content: center;
  height: auto;
  padding-bottom: rem(70px);
  padding-top: rem(70px);
  .footer-small {
    background-color: rgba(255, 255, 255, 0) !important;
  }
  .footer-contact-data {
    justify-content: center;
  }
  img {
    padding: rem(10px);
  }
}

.home {
  .subsite-footer-contact.contact-section {
    background-color: #eeeff1;
    padding: 0 rem(50px)!important;
    display: flex;
    flex-direction: column;
    min-height: 30vh;
    justify-content: center;
    height: auto;
    padding-bottom: rem(70px);
    padding-top: rem(70px);
    width:100%!important;
    left:unset!important;
    @include mq($from: md) {
      padding: 0 8vw!important;
    }
  }
}
