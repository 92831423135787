.news-list {
    display: grid;
    grid-gap: rem(15px);
    row-gap: rem(60px);
    padding-top: rem(100px);
    padding-bottom: rem(100px);
    grid-template-columns: repeat(4, 1fr);
    @include mq($until: md) {
        grid-template-columns: repeat(2, 1fr);
    }
    @include mq($until: sm) {
        grid-template-columns: repeat(1, 1fr);
    }

    &--promo {
        padding-bottom: rem(30px);
    }
    &__header {
        padding-bottom: rem(30px);
        &--grid {
            grid-column-start: span 4;
            padding-bottom: 0;
        }
    }
}
