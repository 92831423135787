.header-box {
  background-color: rgb(255,255,255);

  display: flex;
  flex-direction: column;
  padding: 3em 2em;
  width: 36vw;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  font-size: inherit;
  background-origin: content-box;
  background-size: auto;

  &--border {
    border: 1px solid $color-main;
    margin: 1.25em 1.25em 7.5em 1.25em;
    box-shadow: 0 0 0 1.25em rgba(255, 255, 255, .8);
  }

  @include mq($until: xxl) {
    margin: 0 1.25em 70px 1.25em;
  }

  @include mq($until: xl) {
    padding: 1em 1em;
    margin: 1.25em 1.25em 3.5em 1.25em;
  }

  @include mq($until: lg) {
    width: 100%;
    max-width: unset;
    justify-content: center;
    margin: 0;
    margin-bottom: 4rem;
  }

  &__container {
    width: 100%;

    @include mq($until: lg) {
      margin-top: -2rem;
    }

    &--section3 {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__title {
    margin: 0;
    padding: 0;
  }

  &__sub-title {
    margin-top: 1em;
    margin-bottom: 2em;
    padding: 0;
    text-align: right;
    font-weight: 400;

    @include mq($until: xl) {
      margin-bottom: 1em;
    }
  }

  &--secondary {
    max-width: 400px;

    @include mq($until: xl) {
      width: 25vw;
    }

    @include mq($until: lg) {
      width: 100%;
      max-width: unset;
    }

    @include mq($until: xs) {
      width: 100%;
    }
  }

  p {
    font-size: inherit;
  }
}