.top-slider{
    height: 100vh;
    &__wrapper{
        height: 100%;
        width: 100%;
    }
    &__slide-wrapper{
        height: 100vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    &__box-wrapper{
        width: 100%;
        height: 100%;
        position: relative;
    }
    &__header-box{
        display: grid;
        grid-gap: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: #fff;
        padding: 40px;
        max-width: 600px;
        @include mq($until: md) {
            padding: 30px;
        }
        @include mq($until: sm) {
            padding: 20px;
        }
        &--left{
            left: 0;
        }
        &--right{
            right: 0
        }
        &--border {
            border: 1px solid $color-main;
            margin: 1.25em 1.25em 7.5em 1.25em;
            box-shadow: 0 0 0 1.25em rgba(255, 255, 255, .8);
        }
    }
}
