.slider-baner{
    min-height: 80vh;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include mq($until: md) {
        min-height: 60vh;
    }
    @include mq($until: sm) {
        min-height: 300px;
        align-items: flex-start;
    }
    &__wrapper{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
    &__slide-wrapper{
        height: 80vh;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        @include mq($until: md) {
            height: 60vh;
        }
        @include mq($until: sm) {
            height: 300px;
        }
    }
    &__box-wrapper{
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        &--left{
            justify-content: flex-start;
        }
        &--right{
            justify-content: flex-end;
        }
        @include mq($until: sm) {
            margin-top: 150px;
        }
    }
    &__header-box{
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
        background-color: #fff;
        padding: 40px;
        max-width: 600px;
        @include mq($until: md) {
            max-width: 80%;
            padding: 30px;
        }
        @include mq($until: sm) {
            max-width: 100%;
            position: relative;
            padding: 20px;
            &:before{
                position: absolute;
                z-index: -1;
                content: "";
                top: 0;
                left:0;
                height: 100%;
                width: 100%;
                -webkit-box-shadow: 1px 4px 7px -4px rgba(0,0,0,0.7);
                -moz-box-shadow: 1px 4px 7px -4px rgba(0,0,0,0.7);
                box-shadow: 1px 4px 7px -4px rgba(0,0,0,0.7);
            }

        }
        &--border {
            border: 1px solid $color-main;
            box-shadow: 0 0 0 1.25em rgba(255, 255, 255, .8);
            margin: 1.25em;
        }
    }

}