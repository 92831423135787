// =========================
// Modules and Variables
// =========================

/**
    mq() takes up to three optional parameters:

    $from: inclusive min-width boundary
    $until: exclusive max-width boundary
    $and: additional custom directives
 */
$mq-breakpoints: (
        xs: 576px,
        sm: 768px,
        md: 1024px,
        lg: 1281px,
        xl: 1600px,
        xxl: 1921px,
        retina: 2560px
);

$show-breakpoints: (xs, sm, md, lg, xl, xxl);

$color-main: #ca2628;
$color-extra: #fb7a2c;
$color-background: #eeeff1;
$color-font-light: #fff;
$color-font-dark: #000000;

.background-gray { background-color: $color-background; }

// Font weights
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-bold: 700;
$fw-extra-bold: 700;
$fw-black: 900;

// Font Family
$font-family: "Ubuntu", sans-serif;
$font-family-header: "Ubuntu", sans-serif;

// Base Font for HTML
$base-font-size: 16px;

// Paragraph Styles
$paragraph-color: $color-font-dark;
$paragraph-size: 16px;
$paragraph-line-height: 1.5em;
$paragraph-margin: 20px;

// Header Styles
$header-color: $color-main;
$header-size: 30px;
$header-line-height: 1.25em;
$header-margin: $paragraph-margin * 1.2;

// Blockquote Styles
$blockquote-color: rgba(85, 85, 85, 1);
$blockquote-size: 23px;
$blockquote-line-height: 1.5em;
$blockquote-margin: $paragraph-margin * 1.5;

// Links
$link-color: $color-main;

// hr
$hr-margin: 30px;

// Type Misc
$font-weight-bold: 700;
$border-color: #dadada;
