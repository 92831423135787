.footer-small {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-top: 20px;
    // border-top: 1px solid $color-main;
    padding-top: rem(15px);
    padding-bottom: rem(15px);
    // background-color: $color-background;
    @include mq($until: md) {
        flex-direction: column;
        justify-content: center;

        & > * {
            margin-bottom: rem(10px);
        }
    }

    a {
        display: flex;
        align-items: center;
        span {
            white-space: nowrap;
        }
        img {
            margin-left: rem(10px);
            height: rem(25px);
        }
    }
}
.copyright {
    display: flex;
    align-items: center;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
    z-index: 9;
    min-height: rem(80px);
    .contact-data {
        &__section {
            align-items: center;
            justify-content: center;
        }
        &__icon {
            .svg-icon {
                height: rem(20px);
                fill: $color-font-dark;
            }
        }
    }

    &__nav {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    &__buttons {
        @include mq($until: md) {
            display: none;
        }
        display: flex;
        justify-content: flex-end;
        align-items: center;
        button {
            padding: rem(5px) rem(20px);
            margin-left: rem(30px);
            border: solid rem(1px) $color-main;
            border-radius: rem(5px);
            background-color: #0ff0;
            a {
                color: $color-font-dark;
                font-weight: $fw-medium;
                &:hover {
                    color:$color-main
                }
            }
            @include mq($until: xxl) {
                padding: rem(5px) rem(5px);
                margin-left: rem(10px);
            }
            a {
                font-size: rem(14px);
            }
        }
    }

    &--fixed {
        position: fixed;
        transition: background-color 0.3s ease-in-out;
        &.sticky {
            background-color: $color-font-light;
        }
    }

    &--absolute {
        position: absolute;
    }

    &__logo {
        display: flex;
        align-items: center;
        max-width: 70%;
        img {
            height: rem(50px);
            @include mq($from: lg) {
                height: rem(35px);
            }
            @include mq($from: xl) {
                height: rem(50px);
            }
        }
    }

    &__menuList {
        display: flex;
        justify-content: flex-end;
        align-items: stretch;
        margin: 0;
    }
    &__menuItem {
        margin-top: 0;
        margin-bottom: 0;
        margin-right: rem(25px);
        padding: 0;
        &::before {
            display: none;
        }
    }
    &__menuLink {
        color: $color-font-dark;
        font-size: rem(18px);
        font-weight: $fw-bold;
        @include mq($until: md) {
            border-bottom: rem(1px) solid rgba(255, 255, 255, 0);
            transition: all 0.3s;
        }
        @include mq($from: lg) {
            font-size: rem(15px);
        }

        &:active,
        &--active,
        &--open {
            color: $color-main;
        }

        &:hover {
            @include mq($until: xs) {
                color: white;
                border-bottom: rem(1px) solid rgba(255, 255, 255, 1);
            }
        }
    }
}
