.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
    z-index: 9;
    min-height: rem(80px);
    .contact-data {
        &__section {
            align-items: center;
            justify-content: center;
            a {
                color: $color-font-dark;
                font-weight: $fw-medium;
                &:hover {
                    color: $color-main;
                }
            }
        }
        &__icon {
            .svg-icon {
                height: rem(20px);
                fill: $color-font-dark;
            }
        }
    }

    &__nav {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__buttons {
        @include mq($until: md) {
            display: none;
        }
        display: flex;
        justify-content: flex-end;
        align-items: center;
        button {
            padding: 5px 20px;
            margin-left: 30px;
            border: solid 1px $color-main;
            border-radius: 5px;
            background-color: #0ff0;
            @include mq($until: xxl) {
                padding: 5px 5px;
                margin-left: 10px;
            }
            a {
                font-size: rem(14px);
            }
        }
    }

    &--fixed {
        position: fixed;
        transition: background-color 0.3s ease-in-out;
        &.sticky {
            background-color: $color-font-light;
        }
    }

    &--absolute {
        position: absolute;
    }

    &__logo {
        display: flex;
        align-items: center;
        max-width: 70%;
        img {
            height: rem(50px);
            // @include mq($until: lg) {
            //     height: 35px;
            // }
            // @include mq($from: xl) {
            //     height: 50px;
            // }
        }
    }

    &__menuList {
        display: flex;
        justify-content: flex-end;
        align-items: stretch;
        margin: 0;
    }
    &__menuItem {
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 25px;
        padding: 0;
        &::before {
            display: none;
        }
    }
    &__menuLink {
        color: $color-font-dark;
        font-size: rem(16px);
        font-weight: $fw-bold;

        @include mq($until: md) {
            border-bottom: 1px solid rgba(255, 255, 255, 0);
            transition: all 0.3s;
        }

        @include mq($from: lg) {
            font-size: rem(15px);
        }

        &:active,
        &--active,
        &--open {
            color: $color-main;
        }

        &:hover {
            @include mq($until: xs) {
                color: white;
                border-bottom: 1px solid rgba(255, 255, 255, 1);
            }
        }
    }
}

.top-about {
    @include mq($until: md) {
    }
    @include mq($until: lg) {
        margin-top: -20vh;
    }
    img {
        max-width: 50%;
    }
}
.gallery-about {
    background-color: $color-background;
}

.brand-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    overflow: hidden;
    background: white;
    margin-top: 5vh;
    img {
        filter: grayscale(1);
    }
}
