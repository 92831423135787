.button {
  color: inherit;
  padding: 15px 20px;
  transition: all .3s ease-in-out;
  align-self: flex-start;
  justify-self: flex-start;

  &--main{
    border: 1px solid $color-main;
    color: $color-main;

    &:hover {
      background-color: $color-main;
      border: 1px solid transparent;
      color: white;
    }
  }

  &--reverse{
    border: 1px solid transparent;
    color: white;
    background-color: $color-main;

    &:hover {
      background-color: white;
      border: 1px solid $color-main;
      color: $color-main;
    }
  }
}