.icon-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 55vh;
    &__heading{
        padding-top: 8vw;
        margin-bottom: 5vw;
    }
    &__wrapper{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 100%;
        align-items: flex-start;
    } 
    .color{
       background:  $color-main ;
    }
    &__iconItem{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
        margin: 34px;
        width: 130px;
        img{
            height: 100px;
            width: 100px;
            width: auto;
            margin-bottom: 21px;

            -webkit-filter: opacity(0.5) drop-shadow(0 0 0 $color-main);
            filter: opacity(.5) drop-shadow(0 0 0 $color-main) contrast(250%) contrast(190%) brightness(110%);
            
        }
        p{
            width: 100px;
        }
    }
}