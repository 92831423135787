.top-video{
    position: relative;
    height: 100vh;
    &__wrapper{
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        overflow: hidden;
        video{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            min-width: 100%;
            min-height: 100%;
        }
    }
    &__box-wrapper{
        width: 100%;
        height: 100vh;
        position: relative;
    }
    &__header-box{
        display: grid;
        grid-gap: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: #fff;
        padding: 40px;
        @include mq($until: md) {
            padding: 30px;
        }
        @include mq($until: sm) {
            padding: 20px;
        }
        &--left{
            left: 0;
        }
        &--right{
            right: 0
        }
        &--border {
            border: 1px solid $color-main;
            margin: 1.25em 1.25em 7.5em 1.25em;
            box-shadow: 0 0 0 1.25em rgba(255, 255, 255, .8);
        }
        
    }
}